import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { IDashboard } from "../../../Types/dashboard-details";

const create = async (data: IDashboard) => {
  const res: TServerResponse = await request({
    url: apiurls.dashboardApis.ADD_DETAILS,
    method: "POST",
    data: { planId: data.planId, details: data.details },
  });
  return res;
};

export const useAddDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-all-dashboard"] });
    },
  });
};
