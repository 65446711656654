import { showNotification } from "@mantine/notifications";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getCookie } from "./get.cookies";
import { ROUTES } from "../enum/routes";

const client = axios.create({ baseURL: `${process.env.REACT_APP_BASE_URL}` });

export const request = async (
  options: AxiosRequestConfig,
  disableErrMsg?: boolean
) => {
  const token = getCookie("_auth");
  client.defaults.headers.common.authorization = `Bearer ${token}`;
  const onSuccess = (response: AxiosResponse) => ({
    ...response.data,
    statusCode: response.status,
  });
  const onError = (error: TResponse) => {
    if (error.response.data.status === "error" && !disableErrMsg) {
      showNotification({
        title: error.response?.data?.title,
        message: error.response?.data?.message,
        color: "red",
      });
    }
    console.log("error",error.response.status);
    
    if (error.response.status === 401) {
      if (window.location.pathname !== ROUTES.ADMIN_LOGIN) {
        window.location.replace(`${ROUTES.ADMIN_LOGIN}`);
      }
    }
    return error.response;
  };

  try {
    const response = await client(options); 
    return onSuccess(response);
  } catch (error: unknown) {
    // console.log(error);
    return onError(error as TResponse);
  }
};
