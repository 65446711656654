const prefix = "/admin";
export const coursesApi = {
  GET_PAGINATED_COURSES: prefix + "/get-paginated-courses",
  DELETE_COURSE: prefix + "/delete-course",
  EDIT_COURSE: prefix + "/edit-course",
  ADD_COURSE: prefix + "/create-course",
  GET_SINGLE_COURSE: prefix + "/get-single-course",
  GET_COURSE_NAMES: prefix + "/get-all-course-names",
  GET_ALL_PLANS: prefix + "/get-all-plans",
};
