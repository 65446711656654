import * as Yup from "yup";
import { IDashboard } from "../../Types/dashboard-details";

const initialValues: IDashboard = {
  planId: "",
  details: "",
};
const validationSchema = Yup.object().shape({
  //   planId: Yup.string().required("Plan is required"),
  details: Yup.string().required("Details are required"),
});
export const dashboard_form = {
  initialValues,
  validationSchema,
};
