import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, yupResolver } from "@mantine/form";
import { formValue } from "../../form-values";
import { notifications } from "@mantine/notifications";
import {
  Box,
  Button,
  Flex,
  Group,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import ImageCropInput2 from "../../container/ImageCropPicker/ImageCropInput2";
import RichTextEditorWrapper from "../../components/RichTextEditor/RichTextEditorWrapper";
import { useGetSingleDashboardDetails } from "../../hooks/dashboard-details/queries/useGetSingleDashboardDetails.query";
import { useAddDetails } from "../../hooks/dashboard-details/mutation/useAddDetails.mutation";
import { useEditDetails } from "../../hooks/dashboard-details/mutation/useEditDetails.mutation";
import { IDashboard } from "../../Types/dashboard-details";
import GetAllPlanNames from "../../helper/GetAllPlanNames";
const AddDashboardDetails = () => {
  const { id } = useParams();
  const isEditing = Boolean(id);
  const { data, isLoading } = useGetSingleDashboardDetails(id as string);
  const selectData = GetAllPlanNames();
  const { mutateAsync: addDetails } = useAddDetails();
  const { mutateAsync: editDetails } = useEditDetails();
  const navigate = useNavigate();
  const form = useForm<IDashboard>({
    initialValues: formValue.dashboard_form.initialValues,
    validate: yupResolver(formValue.dashboard_form.validationSchema),
  });

  useEffect(() => {
    if (isEditing && data?.status === "success") {
      const dashboardDetails = data.data[0];
      console.log("dashboard details", dashboardDetails);

      form.setValues({
        planId: dashboardDetails?.planId,
        details: dashboardDetails?.details,
      });
    }
  }, [id, data]);

  const onSubmit = async (values: IDashboard) => {
    try {
      if (isEditing) {
        const update = await editDetails({
          details: values.details,
          id: id as string,
        });
        if (update.status === "success") {
          notifications.show({
            title: "Details Updated",
            message: "Plan details updated",
            color: "green",
          });
          form.reset();
          navigate("/");
        }
      } else {
        const res = await addDetails(values);
        if (res.status === "success") {
          notifications.show({
            title: "Details Added",
            message: "Plan Details has been successfully added.",
            color: "green",
          });
          form.reset();
          navigate("/");
        }
      }
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message:
          error.response?.data?.message || "An unexpected error occurred.",
        color: "red",
      });
    }
  };

  // Render loading state while fetching data
  if (isLoading) {
    return (
      <Box style={{ maxWidth: 800, margin: "2rem auto" }}>
        <Text>Loading...</Text>
      </Box>
    );
  }

  return (
    <Box style={{ maxWidth: 1000, margin: "1rem auto" }}>
      <Text size="xl">
        {isEditing ? "Edit Plan Details" : "Add Plan Details"}
      </Text>
      <Text my={"md"}>
        {isEditing && `${data?.data[0]?.name} - ${data?.data[0]?.packageName}`}
      </Text>
      <form
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        onSubmit={form.onSubmit(onSubmit)}
      >
        <Flex gap="md" align="flex-start" style={{ width: "100%" }}>
          <Select
            mb={12}
            label={"Select Plan"}
            placeholder="Select Plan"
            data={selectData}
            {...form.getInputProps("planId")}
            style={{ flex: 1 }}
            disabled={isEditing}
          />
        </Flex>

        <Text>
          Description <span style={{ color: "red" }}>*</span>
        </Text>
        <RichTextEditorWrapper
          value={form.values.details}
          onChange={(value) => form.setFieldValue(`details`, value)}
        />
        <Group mt="xl">
          <Button type="submit">
            {isEditing ? "Update Details" : "Add Details"}
          </Button>
        </Group>
      </form>
    </Box>
  );
};

export default AddDashboardDetails;
