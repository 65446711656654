import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const get = async () => {
  const res: TServerResponse = await request({
    url: apiurls.coursesApi.GET_ALL_PLANS,
    method: "GET",
  });
  return res;
};

export const useGetAllPlanNames = () => {
  return useQuery({
    queryKey: ["get-all-plan-names"],
    queryFn: () => get(),
  });
};
