import { Box, Button, Flex, Text } from "@mantine/core";
import React, { useMemo, useState } from "react";
import PaginatedTable from "../../components/paginated-table/PaginatedTable";
import { TTableColumns } from "../../Types/table.interface";
import { CONSTANTS } from "../../constants/index.constant";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../enum/routes";
import { useGetPaginatedDashboard } from "../../hooks/dashboard-details/queries/getPaginatedDashboardDetails.query";
import { generateDashboardTable } from "../../constants/tables/dashboard.table";

const Dashboard = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetPaginatedDashboard({
    page,
    limit: CONSTANTS.PAGE_LIMIT,
  });
  const navigate = useNavigate();
  const details = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return (
        (data?.data as []).map((item, index) => ({
          ...(item as unknown as object),
          sno: ((page ?? 1) - 1) * CONSTANTS.PAGE_LIMIT + (index + 1),
        })) ?? []
      );
    }
    return [];
  }, [isLoading, data, page]);
  const columns = useMemo(() => generateDashboardTable(navigate), [navigate]);
  return (
    <Box pr={24}>
      <>
        <Flex justify={"space-between"} align={"center"}>
          <Text fw={600} fz={"h3"} my={24}>
            All Plan Details
          </Text>
          <Button
            color="blue"
            onClick={() => navigate(`${ROUTES.ADD_DASHBOARD_DETAILS}`)}
          >
            Add Dashboard Details
          </Button>
        </Flex>
        <Box>
          <PaginatedTable
            columns={columns as TTableColumns<unknown>[]}
            data={details}
            isLoading={isLoading}
            keyExtractor={(e) => {
              const key = e as { _id: string };
              return key._id;
            }}
            paginationProps={{
              page,
              setPage,
              totalDocuments: Number(data?.extraData ?? "0"),
              pageLimit: CONSTANTS.PAGE_LIMIT,
            }}
          />
        </Box>
      </>
    </Box>
  );
};

export default Dashboard;
