import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import apiurls from "../../apiurls";

const get = async (id: string) => {
  const res: TServerResponse = await request({
    url: apiurls.dashboardApis.GET_SINGLE_DETAILS,
    method: "GET",
    params: { id },
  });
  return res;
};

export const useGetSingleDashboardDetails = (id: string) => {
  return useQuery({
    queryKey: ["get-single-dashboard", id],
    queryFn: () => get(id),
  });
};
