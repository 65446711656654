import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiurls from "../../apiurls";
import { request } from "../../../services/axios.service";
import { IDashboard } from "../../../Types/dashboard-details";
interface Iprops {
  id: string;
  details: string;
}
const create = async ({ id, details }: Iprops) => {
  const res: TServerResponse = await request({
    url: apiurls.dashboardApis.EDIT_DETAILS,
    method: "POST",
    data: { planId: id, details: details },
  });
  return res;
};

export const useEditDetails = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: create,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get-all-dashboard"] });
    },
  });
};
