export const ROUTES = {
  ADMIN_LOGIN: "/login",
  ADMINDASHBOARD: "/",
  ADD_DASHBOARD_DETAILS: "/dashboard/add-details",
  EDIT_DASHBOARD_DETAILS: "/dashboard/edit-details/:id",
  STUDENTS: "/students",
  NEWSLETTER: "/newsletters",
  CONTACT_US: "/contact-us",
  ALUMNIS: "/alumnis",
  BLOGS: "/blogs",
  ADD_BLOG: "/blogs/add-blog",
  EDIT_BLOG: "/blogs/edit-blog/:id",
  COURSE_REVIEWS: "course-reviews",
  LEARNING_MODULE: "learning-module",
  FAQ: "faq",
  COURSES: "/courses",
  ADD_COURSE: "/courses/add-course",
  EDIT_COURSE: "/courses/edit-course/:id",
  LANDING_PAGE: "home-page",
  YOUTUBE_PLAYLIST: "youtube-playlist",
  JOY_RESULT: "joy-result",
  GOOGLE_TOKEN_CREATE: "/google-token-create",
  REGISTERED_STUDENTS: "/transactions",
};
