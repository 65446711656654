import React from "react";
import { TTableColumns } from "../../Types/table.interface";
import CommonActionBar from "../../components/common/ActionBar/CommonActionBar";
import moment from "moment";
import { IDashboard } from "../../Types/dashboard-details";

export const generateDashboardTable = (
  navigate: (path: string) => void
): TTableColumns<IDashboard>[] => [
  {
    key: "sno",
    label: "S No",
  },
  {
    key: "name",
    label: "Name",
    renderCell: (value) => value.name,
  },
  {
    key: "packageName",
    label: "Package Name",
    renderCell: (value) => value.packageName,
  },
  {
    key: "date",
    label: "Date",
    renderCell: (value) =>
      moment(value.createdAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
  {
    key: "updated",
    label: "Updated At",
    renderCell: (value) =>
      moment(value.updatedAt).format("DD-MMM-YYYY - hh:mm:ss A"),
  },
  {
    key: "action",
    label: "Actions",
    renderCell: (value) => (
      <CommonActionBar
        value={value}
        name="Dashboard Details"
        handleEdit={() => navigate(`/dashboard/edit-details/${value.planId}`)}
      />
    ),
  },
];
