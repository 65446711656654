import { useMemo } from "react";
import { useGetAllPlanNames } from "../hooks/courses/query/useGetAllPlanNames.query";
interface IPlans {
  _id: string;
  name: string;
  packageName: string;
}
const GetAllPlanNames = () => {
  const { data } = useGetAllPlanNames();

  const plans: IPlans[] = useMemo(() => {
    if (data?.status === "success") {
      return data.data;
    }
    return [];
  }, [data]);
  const selectData = plans.map((plan) => ({
    label: plan.name + `- ${plan.packageName}`,
    value: plan._id,
  }));
  return selectData;
};

export default GetAllPlanNames;
