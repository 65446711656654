import { alumnis_form } from "./alumnis-form";
import { course_faq_form } from "./course-faq-form";
import { course_form } from "./course-form";
import { course_review_form } from "./course-review-form";
import { dashboard_form } from "./dashboard-form";
import { joyresult_form } from "./joy-result-form";
import { overallHomePage_form } from "./overall-home-page";
import { youtubePlaylist_form } from "./youtube-playlist-form";

export const formValue = {
  youtubePlaylist_form: youtubePlaylist_form,
  joyresult_form: joyresult_form,
  alumnis_form: alumnis_form,
  course_review_form: course_review_form,
  course_form: course_form,
  course_faq_form: course_faq_form,
  overallHomePage_form: overallHomePage_form,
  dashboard_form: dashboard_form,
};
